import { useCallback, useMemo } from 'react';
import { useParams } from 'next/navigation';

const usePagePathWithLocale = () => {
  const { locale } = useParams();

  const getPagePathWithLocale = useCallback(
    (path: string) => {
      return locale ? `/${locale}${path}` : path;
    },
    [locale],
  );

  const cartPagePath = useMemo(() => getPagePathWithLocale('/cart/'), [getPagePathWithLocale]);
  const searchPagePath = useMemo(() => getPagePathWithLocale('/search/'), [getPagePathWithLocale]);
  const checkoutPagePath = useMemo(() => getPagePathWithLocale('/checkout/'), [getPagePathWithLocale]);
  const accountPagePath = useMemo(() => getPagePathWithLocale('/account/'), [getPagePathWithLocale]);
  const loginPagePath = useMemo(() => getPagePathWithLocale('/login/'), [getPagePathWithLocale]);
  const registerPagePath = useMemo(() => getPagePathWithLocale('/register/'), [getPagePathWithLocale]);
  const activationPagePath = useMemo(() => getPagePathWithLocale('/activation/'), [getPagePathWithLocale]);
  const homePagePath = useMemo(() => getPagePathWithLocale('/'), [getPagePathWithLocale]);
  const thankYouPagePath = useMemo(() => getPagePathWithLocale('/thank-you/'), [getPagePathWithLocale]);

  return {
    cartPagePath,
    searchPagePath,
    checkoutPagePath,
    accountPagePath,
    loginPagePath,
    registerPagePath,
    activationPagePath,
    homePagePath,
    thankYouPagePath,
  };
};
export default usePagePathWithLocale;
