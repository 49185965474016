import React, { FC } from 'react';
import Link from 'components/commercetools-ui/atoms/link';
import SelgrosLogo from 'components/icons/logos/SelgrosLogo';
import usePagePathWithLocale from 'helpers/hooks/usePagePathWithLocale';
import { Reference } from 'types/reference';

export interface Props {
  logoLink?: Reference;
  imageClassName?: string;
  onClick?: () => void;
}

const HeaderLogo: FC<Props> = ({ logoLink, imageClassName, onClick }) => {
  const { homePagePath } = usePagePathWithLocale();

  return (
    <div className="flex md:mt-0 xl:pl-0" onClick={onClick}>
      <Link className={imageClassName} link={logoLink ?? homePagePath}>
        <SelgrosLogo />
      </Link>
    </div>
  );
};

export default HeaderLogo;
